<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        {{ name }}
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/system/products')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="name" class="form-label required">
          الاسم
          <span>*</span>
        </label>
        <input
          class="form-control"
          id="name"
          v-model="body.name"
          :class="{ 'is-invalid': isInvalid && !body.name }"
          required
        />
      </div>

      <div class="mb-3">
        <label for="sectionsId" class="form-label required">
          القسم
          <span>*</span>
        </label>

        <select
          class="form-select form-control"
          id="sectionsId"
          name="sectionsId"
          v-model.number="body.sectionsId"
          :class="{ 'is-invalid': isInvalid && !body.sectionsId }"
          required
        >
          <option
            v-for="item of sectionsList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3">
        <label for="name" class="form-label">
          الموظفين المختصين
        </label>
        <MultiSelect  dataKey="id"
          style="width: 100%;text-align:right"
          v-model="body.users"
          :options="usersList"
          optionLabel="name"
          placeholder="اختر الموظفين"
        />
        <!-- :filter="true" -->
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil" v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
          :disabled="disabledAdd"
        />
        <Button
          v-else
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          @click="save()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      body: {
        name: null,
        users: null,
        productsId: 0,
        sectionsId: null,
      },
      users: null,
      id: null,
      usersList: [],
      sectionsList: [],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (this.body.name && this.body.sectionsId) {
        this.disabledAdd = true;
        this.$http.post(`items`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/system/products');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name) {
        this.disabledAdd = true;

        this.$http.put(`items/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });

            this.$router.push('/admin/system/products');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
     if (!this.$checkRoles('productsAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`sections`).then((res) => {
      this.sectionsList = res.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`items/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.productsId = res.data.productsId.id;
          this.body.sectionsId = res.data.sectionsId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    } else {
      this.body.productsId = Number(this.$route.params.productsId);
      this.$http.get(`products/${this.body.productsId}`).then(
        (res) => {
          this.name = res.data.name;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
  watch: {

    'body.sectionsId'(val) {
      this.$http.get(`users?sectionsId=${val}`).then((res) => {
        this.usersList = res.data;
      });
    },
  }
};
</script>
